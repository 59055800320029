<template>
  <div class="device-manage">
    <div class="device-manage-content">
      <filter-bar
        :itemArray="itemArray"
        :initialSelectedIndex="selectedComponentIndex"
        :buttonTitle="selectedComponentIndex != 2 ? '新增设备' : null"
        keywordTitle="搜索设备SN"
        v-model="keyword"
        @onItemSelected="onItemSelected"
        @onButtonClick="onAddDeviceClick"
        @onSearchClick="onSearchClick"
      />
      <component
        ref="component"
        :is="currentComponent"
        @onAddUserClick="onAddUserClick"
        @onRecycleDeviceClick="onRecycleDeviceClick"
      >
      </component>
    </div>
  </div>
</template>

<script>
import FilterBar from "@c/operation-log/common/filter-bar/filter-bar.vue";
import Monitor from "@c/device-manage/monitor/monitor.vue";
import Ring from "@c/device-manage/ring/ring.vue";
import ChildRing from "@c/device-manage/child-ring/child-ring.vue";

export default {
  components: {
    FilterBar,
    Monitor,
    Ring,
    ChildRing,
  },

  data() {
    return {
      selectedComponentIndex: 0,
      keyword: "",
      itemArray: ["监护仪", "监护仪绑定指环", "儿童OSA绑定指环"],
    };
  },

  computed: {
    currentComponent() {
      switch (this.selectedComponentIndex) {
        case 0:
          return "monitor";
        case 1:
          return "ring";
        case 2:
          return "childRing";
        default:
          return "";
      }
    },
    allowOperation() {
      return !this.$store.getters.isSales && !this.$store.getters.isOperationsSpecialist;
    },
  },

  methods: {
    onItemSelected(index) {
      this.selectedComponentIndex = index;
      this.keyword = undefined;
    },

    onSearchClick(keyword) {
      this.$nextTick(() => {
        this.$refs.component.onSearchClick(keyword);
      });
    },

    onAddUserClick(selectedModel) {
      this.$emit("onAddUserClick", selectedModel);
    },

    onAddDeviceClick() {
      if (this.allowOperation) {
        this.$nextTick(() => {
          this.$refs.component.onAddDeviceClick();
        });
      } else {
        this.$toast.showRed("您无权添加设备");
      }
    },

    onRecycleDeviceClick(selectedModel) {
      this.$emit("onRecycleDeviceClick", selectedModel);
    },
  },
};
</script>

<style lang="scss" scoped>
.device-manage {
  background-color: #eff2f7;

  &-content {
    background-color: white;
    box-sizing: border-box;
    padding: 0 20px;
    flex: 1;
  }
}
</style>
